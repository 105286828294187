@import "utils/scss/variables.scss";

.submitPromoContainer {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  flex: 1;

  @media screen and (max-width: $mobileBreakpoint) {
    border: none;
  }
}

.submitPromoForm {
  max-width: 500px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $mobileBreakpoint) {
    img {
      width: 100%;
      max-width: 326px;
    }
  }

  @media screen and (max-width: $hiddenSidebarBreakpoint) {
    max-width: unset;
  }
}

.headerContainer {
  padding: 20px 20px 0 20px;
}

.fieldsContainer {
  overflow: auto;
  padding: 0 20px;
}

.submitPromoButtonsContainer {
  position: sticky;
  padding-bottom: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}
