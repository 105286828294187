.container {
  width: 100%;

  box-shadow: 0px -1px 0px #EEEEEE inset;
}

.title {
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}

.description {
  margin-top: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a1a1;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.subInfo {
  margin-top: 30px;
  display: flex;

  gap: 8px;
  margin-bottom: 8px;
}

.price {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #333333;
}

.weight {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #a5a5a5;
}
