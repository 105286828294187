.container {
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.listViewWrapper {
  gap: 16px;
}

.cardViewWrapper {
  flex-wrap: wrap;
  flex-direction: row;
  gap: 4px;
}

.cardItemWrapper {
  width: 100%;

  &TwoColumns {
    flex: 0 1 calc(50% - 4px);
    max-width: calc(50% - 4px);
  }
}
