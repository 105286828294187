@import 'utils/scss/extensions.scss';

.tablesTreeWrapper {
  @extend %treeWrapper;
}

.treeElement {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.treeTitle {
  margin: 0;
}

.actionButtons {
  min-width: 134px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;

  @media screen and (max-width: 425px) {
    min-width: 67px;
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: 0;
  }
}

.editButton {
  color: #1890ff;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
