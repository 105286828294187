@import "utils/scss/variables.scss";

.titleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.menuButton {
  display: none;
  pointer-events: none;

  @media screen and (max-width: $hiddenSidebarBreakpoint) {
    margin-right: 16px;

    display: unset;
    pointer-events: all;
  }
}
