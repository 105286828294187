.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media screen and (max-width: 425px) {
    margin-bottom: 20px;
  }
}

.pointsTable {
  @media screen and (max-width: 425px) {
    :global {
      .ant-table-thead {
        display: none;
      }

      .ant-table-row {
        width: 150%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .ant-table-cell {
          &:first-of-type {
            background: #fafafa;
          }

          &:last-of-type {
            border: none;
          }
        }
      }
    }
  }

  @media screen and (min-width: 426px) and (max-width: 780px) {
    :global {
      .ant-table-cell {
        padding: 10px;
        font-size: 12px;
      }
    }
  }
}
