.container {
  max-width: 700px;
}

.stopListForm {
  position: relative;
}

.buttons {
  display: flex;
  gap: 20px;
  position: sticky;
  padding-bottom: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}
