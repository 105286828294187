.error {
  // It's should be position: absolute later, after https://app.clickup.com/t/2cxq5mv is done
  // to manage container sizes easier
  // for now let it push other elements
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  font-size: 10px;
  line-height: 1.2;
  color: #ff4018;
}
