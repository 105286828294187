.QRModal {
  :global {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (max-width: 380px) {
    :global {
      .ant-modal-close {
        top: -56px;
      }
    }
  }
}

.QRTitle {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 500;
  line-height: 24px;
}

.QRButton {
  color: #1890ff;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
