@import 'utils/scss/extensions.scss';

.modifiersTreeWrapper {
  @extend %treeWrapper;
}

.treeElement {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.actionButtons {
  display: flex;
  align-items: center;
  margin-left: 20px;
  gap: 8px;
  &:hover {
    cursor: pointer;
  }
}

.editButton {
  color: #1890ff;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
