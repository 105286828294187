@import 'utils/scss/extensions.scss';

.tablesContainer {
  overflow: auto;

  &Hidden {
    @extend %pageContainerHiddenShared;
  }
}

.tablesButtonsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  @media screen and (max-width: 683px) {
    :global {
      .ant-btn {
        min-width: 172px;
      }
    }
  }
}
