@import 'utils/scss/extensions.scss';

.forgotPasswordForm {
  @extend %authForm;
}

.forgotPasswordFormTitle {
  @extend %pageTitleShared;
  margin-bottom: 24px;
  text-align: center;
}

.forgotPasswordFormButtons {
  width: 100%;

  :global {
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.forgotPasswordSubmitButton {
  @extend %submitButtonShared;
  margin-bottom: 10px;
}

.backButton {
  @extend %textButtonShared;
  align-self: center;
}
