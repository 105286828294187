@import 'utils/scss/extensions.scss';

.loginForm {
  @extend %authForm;
}

.loginFormTitle {
  @extend %pageTitleShared;
  margin-bottom: 24px;
  text-align: center;
}

.flexContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginFormButtons {
  width: 100%;

  :global {
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.forgotPasswordButton {
  @extend %textButtonShared;
  margin-bottom: 24px;
  line-height: 24px;
  align-self: flex-end;
}

.loginSubmitButton {
  @extend %submitButtonShared;
}
