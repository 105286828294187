@import 'utils/scss/extensions.scss';

.submitModifierContainer {
  @extend %submitPageContainerShared;
  padding: 0;
}

.submitModifierForm {
  max-width: 500px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  padding: 20px 20px 0 20px;
}

.fieldsContainer {
  overflow: auto;
  padding: 0 20px;
}

.submitButtonsContainer {
  position: sticky;
  padding-bottom: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}
