@import 'utils/scss/variables.scss';

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  margin: 0 auto;
}

.contentContainer {
  flex: 1;
  position: relative;
  overflow: auto;
  display: flex;
  max-width: 1240px;

  & > * {
    flex: 1 1 50%;
    max-width: 50%;

    @media screen and (max-width: $mobileBreakpoint) {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
}
