@import 'utils/scss/extensions.scss';

.submitTableContainer {
  @extend %submitPageContainerShared;
  flex: 1;
}

.submitTableForm {
  box-sizing: border-box;
}

.submitTableButtonsContainer {
  position: sticky;
  position: -webkit-sticky;
  padding-bottom: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}
