@import 'utils/scss/extensions.scss';

.promoPageContainer {
  overflow: auto;

  &Hidden {
    @extend %pageContainerHiddenShared;
  }
}

.promoItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding-bottom: 24px;
}
