@import 'utils/scss/extensions.scss';

.submitDishContainer {
  position: relative;
  @extend %submitPageContainerShared;
  padding: 0;
}

.submitDishForm {
  max-width: 500px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  padding: 20px 20px 0 20px;
}

.fieldsContainer {
  position: relative;
  overflow: auto;
  padding: 0 20px;
}

.weightContainer {
  display: flex;

  .weightType {
    width: 100px;
    margin-left: 8px;
  }
}

.descriptionContainer {
  :global {
    .ant-input {
      height: 108px;
    }
  }
}

.submitDishButtonsContainer {
  position: sticky;
  padding-bottom: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}

.sizesItem {
  display: flex;
  flex-direction: column;

  :global {
    .ant-form-item {
      margin-bottom: 30px;
    }

    .ant-form-item-label label {
      font-size: 12px;
    }
  }
}

.sizeInputs {
  width: 100%;
  display: flex;
  gap: 16px;

  :global {
    .ant-form-item {
      flex: 1;
    }

    @media screen and (max-width: 575px) {
      .ant-form,
      .ant-form-item {
        flex-direction: row;
      }
    }
  }
}

.sizeButtons {
  display: flex;
  gap: 8px;
  margin-bottom: 30px;
}

.sizeText {
  display: block;
  text-align: center;
  align-self: center;
  color: #1890ff;
}

.image {
  width: 100%;
  display: flex;
  gap: 16px;

  :global {
    .ant-form-item {
      flex: 1;
    }

    @media screen and (max-width: 575px) {
      .ant-form,
      .ant-form-item {
        flex-direction: row;
      }
    }
  }
}

.sizeButtons {
  display: flex;
  gap: 8px;
  margin-bottom: 30px;
}

.sizeText {
  display: block;
  text-align: center;
  align-self: center;
  color: #1890ff;
}

.image {
  width: 100%;
  object-fit: contain;
}
