@import 'utils/scss/variables.scss';
@import 'utils/scss/extensions.scss';

.wrapper {
  @media screen and (max-width: $hiddenSidebarBreakpoint) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-in-out;
  }
}

.container {
  width: $sidebarWidth;
  height: 100vh;
  padding: 52px 0 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #313a46;
  transition: left 0.2s ease-in-out;

  @media screen and (max-width: $hiddenSidebarBreakpoint) {
    position: absolute;
    top: 0;
    left: -100%;
    right: 0;
    bottom: 0;
    z-index: 1000;
    box-shadow: 0 3px 6px -4px rgba(0 0 0 / 12%), 0 6px 16px 0 rgba(0 0 0 / 8%), 0 9px 28px 8px rgba(0 0 0 / 5%);
  }
}

.userInfo {
  margin: 0 12px 25px;
  color: #fff;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 0 10px;
}

.avatar {
  grid-area: 1/1/4/2;
  align-self: center;
}

.role {
  font-size: 12px;
}

.name {
  font-size: 16px;
}

.pointInfo {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.restaurantLink {
  @extend %textButtonShared;
  font-size: 12px;
  line-height: 20px;

  &:hover {
    opacity: 0.8;
  }
}

.navTitle {
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 0.6875rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #8391a2;
}

.linksContainer {
  display: flex;
  flex-direction: column;
}

.link {
  margin-bottom: 8px;
  padding: 9px 24px;
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #bfcde2;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(191, 205, 226, 0.1);
    color: #fff;
  }

  &Active {
    background-color: rgba(191, 205, 226, 0.2);
    color: #fff;

    &:hover {
      background-color: rgba(191, 205, 226, 0.2);
      color: #fff;
    }
  }
}

.divider {
  margin: 0 24px 8px;
  height: 1px;
  background-color: #bfcde2;
}

.helpLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.helpIcon {
  transform: rotate(180deg) scale(-1, 1);
}

.settingsContainer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

.logoutButton {
  margin: 0 24px;
  border: none;
  background-color: #4d5765;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #272d34;
  }
}

.closeSideBarButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: transparent;
  border: none;
  font-size: 20px;
  color: #fff;
}

.visibleOnMobile {
  opacity: 1;
  pointer-events: all;
  overflow: auto;

  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.mainLinksContainer {
  margin: 8px 0;
  padding: 28px 0;
  overflow-y: auto;
}
