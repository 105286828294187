.full-width {
  width: 100%;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.flex-direction-vertical {
  flex-direction: column;
}

.flex-direction-horizontal {
  flex-direction: row;
}

.flex-align-items-center {
  align-items: center;
}

.relative {
  position: relative;
}

