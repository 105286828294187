@import 'utils/scss/extensions.scss';

.initialPasswordForm {
  @extend %authForm;
}

.initialPasswordFormTitle {
  @extend %pageTitleShared;
  margin-bottom: 30px;
  text-align: center;
}

.initialPasswordSubmitButton {
  @extend %submitButtonShared;
}
