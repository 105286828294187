.container {
  background-color: #fff;
  border-radius: 8px;
  padding: 8px;

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.imageContainer {
  width: 100%;
  padding-top: 61%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.infoContainer {
  padding: 8px;
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #333333;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;

}

.weight {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #a5a5a5;
}

.description {
  margin-top: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a1a1;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.price {
  margin-top: 30px;

  font-weight: 500;
  color: #333333;
  font-size: 16px;
  line-height: 24px;
}
