.pageWrapper {
  height: 100vh;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../../images/background-icon-1.svg') no-repeat top 10% left 7%,
    url('../../images/background-icon-2.svg') no-repeat bottom 10% left 0,
    url('../../images/background-icon-3.svg') no-repeat top 9% right 20%,
    url('../../images/background-icon-4.svg') no-repeat bottom 12% right 0;
  background-color: #f0f2f5;

  @media screen and (max-width: 640px) {
    background-size: 125px 105px, 96px 78px, 36px 35px, 126px 150px;
  }
}

.logoContainer {
  margin: 0 0 40px;
  display: flex;
  justify-content: center;
}

.card {
  width: 100%;
  max-width: 360px;
  background: transparent;
  border: none;

  :global {
    .ant-card-body {
      padding: 0;
    }
  }
}
