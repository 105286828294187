.container {
  max-width: 480px;
  margin-bottom: 20px;
}

.info {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 683px) {
    flex-direction: column;
    align-items: flex-start;

    .dishesNumber {
      padding-left: 15px;
    }
  }
}
