.hiddenClickableOverlay {
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -12px;
  right: -12px;
  cursor: pointer;
  transition: background .3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.categoryCheckmark {
  position: absolute;

  right: 0;
  top: 50%;
  transform: translateY(-50%);

  svg {
    color: #1890ff;
    width: 14px;
    height: 14px;
  }
}
